import { Component, Inject, Input, OnInit, Renderer2 } from "@angular/core";
import { ArenaEventComponent } from "@fca-app/dashboard/components/home/arena-list/arena-details/arena-events/arena-event/arena-event.component";
import { StartStopEventComponent } from "@fca-app/dashboard/components/home/arena-list/arena-details/arena-events/arena-event/video-link-input/start-stop-event.component";
import { VideoViewerComponent } from "@fca-app/dashboard/components/home/arena-list/arena-details/video-viewer/video-viewer.component";
import { EventStatus } from "@fca-app/enums/event-status.enum";
import { ArenaModel } from "@fca-app/models/users/arena/arena.model";
import { FightEventModel } from "@fca-app/models/users/arena/fight-event/fight-event.model";
import { ArenasService } from "@fca-app/services/arenas.service";
import { DownloadDataService } from "@fca-app/services/download-data.service";
import { WebHooksService } from "@fca-app/services/web-hooks.service";
import { ManageChatIframeComponent } from "@fca-app/shared/components/manage-chat-iframe/manage-chat-iframe.component";
import { EAdminPermissions } from "@fca-app/shared/enums/admin-permissions.enum";
import { WINDOW } from "@fca-app/shared/inject/window-injection";
import { UntilDestroy, untilDestroy } from "@fca-app/shared/operator/until-destroy.operator";
import { FcaPreloadService } from "@fca-app/shared/services/fca-preload.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { combineLatest } from "rxjs";
import { finalize, switchMap, take, tap } from "rxjs/operators";

@UntilDestroy()
@Component({
    selector: 'app-arena-events',
    styleUrls: ['./arena-events.component.scss'],
    templateUrl: './arena-events.component.html',
})
export class ArenaEventsComponent implements OnInit {
    @Input() arena: ArenaModel;
    @Input() id: number;
    events = new Map<EventStatus, FightEventModel[]>([
        [EventStatus.Ongoing, []],
        [EventStatus.Upcoming, []],
        [EventStatus.Finished, []],
        [EventStatus.Canceled, []],
    ]);
    permissions = EAdminPermissions;

    constructor(
        @Inject(WINDOW) private window: Window,
        private renderer: Renderer2,
        public readonly preloadService: FcaPreloadService,
        private readonly arenasService: ArenasService,
        private readonly modalService: NzModalService,
        private readonly downloadDataService: DownloadDataService,
        private readonly nzNotificationService: NzNotificationService,
        private readonly webHooksService: WebHooksService
    ) {}

    onStartEventClick(event: FightEventModel): void {
        this.modalService.create({
            nzTitle: 'Start event',
            nzContent: StartStopEventComponent,
            nzComponentParams: {
                inputPlaceholder: 'Stream url',
                action: 'start',
                fightEvent: event,
            },
            nzOnOk: (res: StartStopEventComponent) => {
              if (res.value && res.type) {
                this.webHooksService
                    .startEvent(event.id, 'STARTED', res.value, res.type)
                    .pipe(take(1), untilDestroy(this), finalize(() => location.reload()))
                    .subscribe(() => {
                      event.status = EventStatus.Ongoing;
                    });
              } else {
                this.nzNotificationService
                  .error('Something went wrong', 'Select type and put a link', {
                    nzDuration: 5000,
                  })
                  .onClick.pipe(take(1), untilDestroy(this));
              }
            },
        });
    }

    onStopEventClick(event: FightEventModel): void {
        this.modalService.create({
            nzTitle: 'Stop event',
            nzContent: StartStopEventComponent,
            nzComponentParams: {
                inputPlaceholder: 'Video url',
                action: 'stop',
                fightEvent: event,
            },
            nzOnOk: (res: StartStopEventComponent) => {
              if (res.value && res.type) {
                this.webHooksService
                  .stopEvent(event.id, 'ENDED', res.value, res.type)
                  .pipe(take(1), untilDestroy(this), finalize(() => location.reload()))
                  .subscribe();
              } else {
                this.nzNotificationService
                  .error('Something went wrong', 'Select type and put a link', {
                    nzDuration: 5000,
                  })
                  .onClick.pipe(take(1), untilDestroy(this));
              }
            },
        });
    }

    onManageChat(event: FightEventModel): void {
        this.modalService.create({
            nzContent: ManageChatIframeComponent,
            nzFooter: null,
            nzWidth: '95%',
            nzComponentParams: { fightEvent: event },
        });
    }

    eventHasVideo(event: FightEventModel): boolean {
        return String(event.video?.streamUrl).endsWith('.m3u8') || String(event.video?.videoUrl).endsWith('.m3u8');
    }

    eventVideoSrc(event: FightEventModel): string | null {
        return event.video?.streamUrl || event.video?.videoUrl || null;
    }

    count(status: EventStatus): number {
        return this.events.get(status)?.length || 0;
    }

    ngOnInit() {
        this.arenasService.needUpdateArenas$
            .pipe(
                tap(() => this.preloadService.preload(true)),
                switchMap(() =>
                    // this.arenasService.getArenaEvents(this.id, [
                    //     EventStatus.Upcoming,
                    //     EventStatus.Ongoing,
                    //     EventStatus.Finished,
                    //     EventStatus.Canceled,
                    // ])
                  combineLatest([
                    this.arenasService.getArenaEvents(this.id, [EventStatus.Upcoming]),
                    this.arenasService.getArenaEvents(this.id, [EventStatus.Ongoing]),
                    this.arenasService.getArenaEvents(this.id, [EventStatus.Finished]),
                    this.arenasService.getArenaEvents(this.id, [EventStatus.Canceled]),
                  ])
                ),
                untilDestroy(this)
            )
            .subscribe(([upcoming, ongoing, finished, canceled]) => {
                this.preloadService.preload(false);
                this.events.set(EventStatus.Upcoming, upcoming);
                this.events.set(EventStatus.Ongoing, ongoing);
                this.events.set(EventStatus.Finished, finished);
                this.events.set(EventStatus.Canceled, canceled);
            });
        this.arenasService.needUpdateArenas$.next();
    }

    onEditClick(event: FightEventModel): void {
        this.modalService.create({
            nzTitle: event.name,
            nzContent: ArenaEventComponent,
            nzWidth: '100%',
            nzOnOk: () => {
                this.arenasService
                    .updateFightEvent(event)
                    .pipe(take(1), untilDestroy(this))
                    .subscribe(() => {
                        this.arenasService.needUpdateArenas$.next();
                    });
            },
            nzComponentParams: {
                fightEvent: event,
                arena: this.arena,
                previewPlaceholder: event.image?.href,
            },
        });
    }

    onPlayVideo(event: FightEventModel): void {
        this.modalService.create({
            nzContent: VideoViewerComponent,
            nzFooter: null,
            nzCloseIcon: undefined,
            nzWidth: '60%',
            nzComponentParams: { videoSrc: this.eventVideoSrc(event) },
        });
    }

    onDownloadJsonClick(event: FightEventModel): void {
        this.downloadDataService
            .downloadEventJson(event)
            .pipe(take(1), untilDestroy(this))
            .subscribe();
    }

    /**
     * Обработчик собития от iframe
     * @param event
     */
    private messageHandler(event: MessageEvent) {
        // console.log(event);
        // const data = event.data as IframeMessageData;
        // if (data && data.action) {
        //   switch (data.action) {}
        // }
    }
}
