import { AfterViewInit, Component, OnInit } from "@angular/core";
import { EUserRoleName } from "@fca-app/enums/user-role-names.enum";
import { DownloadDataService } from "@fca-app/services/download-data.service";
import { ELocale } from "@fca-app/shared/enums/locale.enum";
import { untilDestroy, UntilDestroy } from "@fca-app/shared/operator/until-destroy.operator";
import { FcaPreloadService } from "@fca-app/shared/services/fca-preload.service";
import { NzNotificationService } from "ng-zorro-antd/notification";
import { Subject } from "rxjs";
import { finalize, take } from "rxjs/operators";

export interface IStatisticsRequest {
  languages: string[];
  roles: EUserRoleName[];
  activity: string;
}

const year = new Date().getFullYear();

function countDaysUpToMonth(month: number) {
  let days = 0;
  for (let i = 1; i <= month; i++) {
    days += new Date(year, i, 0).getDate();
  }
  return days;
}

@UntilDestroy()
@Component({
  selector: "statistics-user",
  templateUrl: "./statistics-users.component.html",
  styleUrls: ["./statistics-users.component.scss"]
})
export class StatisticsUsersComponent implements OnInit, AfterViewInit {

  filterChange$ = new Subject<boolean>();

  readonly languages = Object.values(ELocale) as string[];
  readonly roles: { label: string; value: string }[] = [
    {
      value: EUserRoleName.FAN,
      label: "Fan"
    },
    {
      value: EUserRoleName.FIGHTER,
      label: "Fighter"
    },
    {
      value: EUserRoleName.LOCATION_PROVIDER,
      label: "Arena"
    }
  ];
  readonly activities: { label: string; value: string }[] = [
    {
      label: "7 days",
      value: "7"
    },
    {
      label: "14 days",
      value: "14"
    },
    {
      label: "21 days",
      value: "21"
    },
    ...Array.from({ length: 12 }, (_, i) => i + 1)
      .map((num) => ({
        label: `${num} month${num === 1 ? "" : "s"}`,
        value: String(countDaysUpToMonth(num))
      }))
  ];

  request: IStatisticsRequest = {
    languages: [ELocale.PT],
    roles: [],
    activity: "7"
  };

  constructor(
    public readonly preloadService: FcaPreloadService,
    private readonly downloadDataService: DownloadDataService,
    private readonly nzNotificationService: NzNotificationService
  ) {
  }

  ngOnInit(): void {
    // this.filterChange$.pipe(untilDestroy(this)).subscribe(() => {
    //   console.log(this.request);
    // });
  }

  onDownloadClick(): void {
    this.preloadService.preload(true);
    this.downloadDataService
      .downloadUsersStatisticsCsv(this.request.roles, Number(this.request.activity), this.request.languages)
      .pipe(take(1), untilDestroy(this), finalize(() => this.preloadService.preload(false)))
      .subscribe((res) => {
        if (!res) {
          this.nzNotificationService
            .info("Empty result", "Data is empty", {
              nzDuration: 5000
            })
            .onClick.pipe(take(1), untilDestroy(this));
        }
      });
  }

  get downloadDisabled() {
    return this.request.roles.length === 0;
  }

  public isNotSelectedLanguage(value: string): boolean {
    return this.languages.indexOf(value) === -1;
  }

  ngAfterViewInit(): void {
    this.filterChange$.next(true);
  }
}
