import { Component, Input, OnInit } from "@angular/core";
import { EVideoStatus } from "@fca-app/api/fca/arenas/interfaces/response/video-api.response";
import { FightEventType } from "@fca-app/api/fca/web-hooks/interfaces/params/start-stop-event.params";
import { FightEventModel } from "@fca-app/models/users/arena/fight-event/fight-event.model";

@Component({
    selector: 'modal-start-stop-event',
    templateUrl: './start-stop-event.component.html',
    styleUrls: ['./start-stop-event.component.scss'],
})
export class StartStopEventComponent implements OnInit {
    value: string | null = null;
    type: FightEventType | null = null;
    fightEventTypes: string[] = Object.values(FightEventType as any) as string[];
    @Input() inputPlaceholder: string = 'Enter text...';
    @Input() action: 'start' | 'stop';
    @Input() fightEvent: FightEventModel;

    constructor() {}

    onClickType(type: string): void {
      this.type = type as FightEventType;
    }

    ngOnInit(): void {
      // можем запустить то что не FINISHED
      if (this.action === 'start') {
        if (this.fightEvent.video!.status === EVideoStatus.FINISHED) {
          this.fightEventTypes = this.fightEventTypes.filter(fet => fet !== FightEventType.FIGHT_EVENT);
        }
        if (this.fightEvent.conference?.video!.status === EVideoStatus.FINISHED) {
          this.fightEventTypes = this.fightEventTypes.filter(fet => fet !== FightEventType.CONFERENCE);
        }
        if (this.fightEvent.weighting?.video!.status === EVideoStatus.FINISHED) {
          this.fightEventTypes = this.fightEventTypes.filter(fet => fet !== FightEventType.WEIGHTING);
        }
      }
      // можем остановить только то, что в LIVE
      else if (this.action === 'stop') {
        if (this.fightEvent.video!.status === EVideoStatus.LIVE) {
          this.fightEventTypes = [FightEventType.FIGHT_EVENT];
        }
        if (this.fightEvent.conference?.video!.status === EVideoStatus.LIVE) {
          this.fightEventTypes = [FightEventType.CONFERENCE];
        }
        if (this.fightEvent.weighting?.video!.status === EVideoStatus.LIVE) {
          this.fightEventTypes = [FightEventType.WEIGHTING];
        }
      }

    }
}
