import { EventStatus } from "@fca-app/enums/event-status.enum";
import { VideoModel } from "@fca-app/models/video/video.model";
import * as moment from "moment-timezone";

export interface FightEventStartInfoModelData {
  video?: VideoModel;
  fromDate: moment.Moment;
  toDate: moment.Moment;
  status: EventStatus;
}

export interface FightEventStartInfoModel extends FightEventStartInfoModelData {}

export class FightEventStartInfoModel {}
