import { Injectable } from "@angular/core";
import { FcaUsersStatisticsService } from "@fca-app/api/fca/statistics/fca-users-statistics.service";
import { FightEventModel } from "@fca-app/models/users/arena/fight-event/fight-event.model";
import * as moment from "moment";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

enum FileExtension {
  Csv = ".csv",
  Json = ".json",
}

@Injectable()
export class DownloadDataService {
  constructor(private readonly usersStatisticsApiService: FcaUsersStatisticsService) {
  }

  downloadUsersStatisticsCsv(roles: string[], activity: number, languages: string[]): Observable<boolean> {
    return this.usersStatisticsApiService.downloadStatistics({ roles, activity, languages }).pipe(
      map((data) => {
        if ((data as unknown as Blob).size === 0) {
          return false;
        }

        const downloadURL = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = downloadURL;
        link.download = this.prepareFilename(`users-statistics (${roles.join(", ")})`, FileExtension.Csv);
        link.click();

        return true;
      })
    );
  }

  downloadEventJson(event: FightEventModel): Observable<any> {
    return this.usersStatisticsApiService.downloadEventJson(event.id).pipe(
      tap(data => {
        const downloadURL = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = downloadURL;
        link.download = this.prepareFilename(event.name || "unknown", FileExtension.Json);
        link.click();
      })
    );
  }

  private prepareFilename(name: string, extension: FileExtension): string {
    const today = moment().format("DD-MM-YYYY");
    return `[${today}] ${name}${extension}`;
  }
}
