import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FcaArenasApiService } from '@fca-app/api/fca/arenas/fca-arenas-api.service';
import { FcaAuthApiService } from '@fca-app/api/fca/auth/fca-auth-api.service';
import { FcaEmailApiService } from '@fca-app/api/fca/email/fca-email-api.service';
import { FcaFightEventsApiService } from "@fca-app/api/fca/fight-events/fca-fight-events-api.service";
import { FcaFightsApiService } from '@fca-app/api/fca/fights/fca-fights-api.service';
import { FcaImagesApiService } from '@fca-app/api/fca/images/fca-images-api.service';
import { FcaMatchmakingApiService } from "@fca-app/api/fca/matchmaking/fca-matchmaking-api.service";
import { FcaUsersStatisticsService } from '@fca-app/api/fca/statistics/fca-users-statistics.service';
import { FcaUsersApiService } from '@fca-app/api/fca/users/fca-users-api.service';
import { FcaWebHooksApiService } from '@fca-app/api/fca/web-hooks/fca-web-hooks-api.service';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        FcaEmailApiService,
        FcaUsersApiService,
        FcaArenasApiService,
        FcaAuthApiService,
        FcaUsersApiService,
        FcaUsersStatisticsService,
        FcaImagesApiService,
        FcaFightsApiService,
        FcaWebHooksApiService,
        FcaFightEventsApiService,
        FcaMatchmakingApiService,
    ],
})
export class FcaApiModule {}
